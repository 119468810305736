import type { Alpine } from 'alpinejs'
import Swiper, { FreeMode, Navigation, SwiperOptions } from 'swiper';

/**
 * Стандартный компонент слайдера на базе swiper.
 *
 * @param Alpine
 */
export function slider(Alpine: Alpine) {
  Alpine.data('slider', sliderComponent);

  function sliderComponent() {
    return {
      swiper: null,
      init() {
        this.$nextTick(() => {
          if (this.$refs.swiper) this.initSwiper();
        });
      },
      initSwiper() {
        let params: SwiperOptions = {
          modules: [FreeMode],
          direction: 'horizontal',
          freeMode: true,
          slidesPerView: 'auto',
        };
        if (this.$refs.prev && this.$refs.next) {
          params.modules.push(Navigation);
          params.navigation = {
            nextEl: this.$refs.next,
            prevEl: this.$refs.prev,
          };
        }
        this.swiper = new Swiper(this.$refs.swiper, params);
      },
      destroy() {
        if (this.swiper) this.swiper.destroy();
      },
    };
  }
}
